
import { defineComponent ,onBeforeMount,reactive,ref, toRefs } from 'vue';
import 'dayjs/locale/zh-cn';
import  { Dayjs } from 'dayjs';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { getMemberList,memberExport } from '@/api/personal';
import { getPickerTime } from '@/utils/common'
import { message } from 'ant-design-vue';
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
export default defineComponent({
    name:'member',
    components:{
        breadcrumb
    },
    setup(){
        // 面包屑
        const breadData = ref<Array<object>>([
            {title:'首页',path:'#/'},
            {title:'个人',path:'/index'},
            {title:'我的会员',path:'#/'},
        ])
        const value2 = ref<Dayjs[]>([]);
        const query = reactive({
            mobile:'',
            channel:null,
            s_time:'',
            e_time:'',
            per_page:10,
            page:1,
        });
        const total = ref<number>(1)
        const columns = [
            { title: '序号', width: 60, dataIndex: 'num', key: 'num', fixed: true },
            { title: '加入时间',dataIndex: 'create_time', key: 'create_time'},
            { title: '使用会员', dataIndex: 'nickname', key: 'nickname'},
            { title: '会员状态', dataIndex: 'vip_status', key: 'vip_status'},
            { title: '头像', dataIndex: 'avatar', key: 'avatar' },
            { title: '支付', dataIndex: 'pay_status', key: 'pay_status' },
            { title: '会员卡有效期', dataIndex: 'start_time', key: 'start_time', },
            { title: '销售渠道', dataIndex: 'channel', key: 'channel', width: 150 },
            { title: '最近登录时间', dataIndex: 'last_time', key: 'last_time'},
        ];
        
        const data = ref<Array<any>>([])


        onBeforeMount(()=>{
            let role_id = localStorage.getItem('userInfo')
            role_id = JSON.parse(role_id as string)
            if((role_id as any).role_id == 5){
                breadData.value.splice(2,1)
            }
            getList()
        })

        const pickerChange = (value:any):void =>{
            let data:any = getPickerTime(value2.value,0);

            query.s_time = data.start;
            query.e_time = data.end;
        }

        const getList = (val?:string):void =>{
            if(val){
                query.page = 1;
            }
            getMemberList(query).then((res:any)=>{
                if(res.code == 200){
                    
                //    query.s_time = '';
                //    query.e_time = '';
                //    query.mobile = '';
                //    (query.channel as any) = null;
                   data.value = res.data;
                   total.value = res.total;
                   data.value.forEach((item,idx) => {
                       if(query.page == 1){
                            item.num = idx +1;
                       }else{
                            if(idx == 0){
                                item.num = ((query.page * 10) - 10) + 1;
                            }else{
                                item.num = ((query.page * 10) - 10) + idx+1;
                            }
                       }
                   });
                }
            })
        }

        const tableChange = (pagination:any):void =>{
            query.page = pagination.current;
            query.per_page = pagination.pageSize;
            getList()
        }

        const querySearch = (val:string):void =>{
             if(!value2.value){
                query.s_time = '';
                query.e_time = '';
            }
            if(!/^[\da-z]+$/i.test(query.mobile) && query.mobile != ''){
              message.error('只能输入数字或英文')
              return
            }
            getList(val)
        }

        // 导出
        const exportExcel = ():void =>{
            let info:any = localStorage.getItem('userInfo')
            info = JSON.parse(info)
            let url = `agent-api/api/member/export?user_id=${info.id}&mobile=${query.mobile}&channel=${query.channel}&s_time=${query.s_time}&e_time=${query.e_time}`
            if(process.env.NODE_ENV === "development"){
                window.open('https://v2.diancang.site/'+ url)
            }else{
                if(location.origin == 'http://agent.huobite.club'){
                    window.open('https://v2.diancang.site/'+ url)
                }else{
                    window.open(location.origin +'/agentApi/'+ url)
                }
            }
            // memberExport().then((res:any)=>{
            //     if(res.code == 200){
                    
            //     }
            // })
        }

        return{
            ...toRefs(query),
            value2,
            locale,
            data,
            columns,
            total,
            exportExcel,
            pickerChange,
            tableChange,
            querySearch,
            breadData
        }
    }
})
